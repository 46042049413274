import React, { useEffect, useState, useContext }  from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
} from "react-router-dom";

import { useAuth0 } from "@auth0/auth0-react";

import Home from './views/home';
import Profile from './views/profile';
import Library from './views/library';
//import Success from './views/order';
//import Live from './views/live';
//import Streamer from './views/streamer';
import Viewer from './views/viewer';
import Fighters from './views/fighters';
import Search from './views/all-fighters';
import Purchases from './views/purchases';

import NavBar from './components/nav-bar';
import UserContext from './components/user-data';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';


import './App.css';
import useScript from './hooks/useScript';

import '@vidstack/player/hydrate.js';

import ReactGA from 'react-ga4';
  const TRACKING_ID = "G-DNGQR1NM0Q"; // OUR_TRACKING_ID
  ReactGA.initialize(TRACKING_ID);

function App() {
    
  useEffect(() => {
    ReactGA.send(window.location.pathname + window.location.search)
    // ReactGA.pageview(window.location.pathname + window.location.search);      
  }, []);
    
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const [profile, setProfile] = React.useState();
  
  var server = 'https://ase-server.vercel.app/api/';
    
  async function getUser() {
    const fetchingUser = await fetch (`${server}users`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: user.email,
      }),
    })
    .then((response) => response.json())
    .then((data) => {

      if (data && data.email !== user.email) {
        console.error("Empty array")
      }
      else {
        setProfile(data);
//        console.log (data)
      }
    })
    .catch((error) => {
      console. error ('Error:', error);
    });
  }
    


  document.title = "Arte Suave Elite Network";

  // useScript('https://polyfill.io/v3/polyfill.min.js?version=3.52.1&features=fetch');
  // useScript('https://js.stripe.com/v3/');

  if (isLoading) {
    return <div className="App-cover"><div className="App-container">Loading ...</div></div>;
    
  }
  
  if (isAuthenticated && !profile) {
      getUser()
      //console.log("Requested user")
  }
    
//  console.log(profile.firstName + " " + profile.lastName ) 
 
  return (
    <UserContext.Provider value={profile}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <div className="App">
          <header className="App-header">
            <NavBar />
          </header>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/library" element={<Library />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/fighters" element={<Fighters />} />
            <Route path="/view" element={<Viewer />} />
            <Route path="/search" element={<Search />} />
              <Route path="/purchases" element={<Purchases />} />
          </Routes>

        </div>
      </LocalizationProvider>
    </UserContext.Provider>
  );
}

export default App;

// Chatbot code goes in import area
// import LiveChat from './components/live-chat'

// Following code for Chat Bot goes right after </Routes>
//      <LiveChat />


// <Elements stripe={stripePromise} options={options}>
//   <CheckoutForm />
// </Elements>


// <!-- Google tag (gtag.js) -->
// <script async src="https://www.googletagmanager.com/gtag/js?id=G-DNGQR1NM0Q"></script>
// <script>
// window.dataLayer = window.dataLayer || [];
// function gtag(){dataLayer.push(arguments);}
// gtag('js', new Date());
//
// gtag('config', 'G-DNGQR1NM0Q');
// </script>


//<Route path="/live" element={<Live />} />
//<Route path="/order" element={<Success />} />
//<Route path="/streams" element={<Streamer />} />
//<Route path="/view" element={<Viewer />} />