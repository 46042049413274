import React, {useEffect, useState, useContext, useRef} from "react";
// import Buy from './../components/buy';
import Moment from 'moment';
import { NavLink, Link, useNavigate } from "react-router-dom";
//import {eventCount} from './get-future-events';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Alert from '@mui/material/Alert';

import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import CircleIcon from '@mui/icons-material/Circle';
import Typography from '@mui/material/Typography';

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PlaceIcon from '@mui/icons-material/Place';

import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { google, outlook, office365, yahoo, ics } from "calendar-link";

import SportsKabaddiIcon from '@mui/icons-material/SportsKabaddi';
import ViewListIcon from '@mui/icons-material/ViewList';
import ListIcon from '@mui/icons-material/List';
import RuleIcon from '@mui/icons-material/Rule';

import Menu from '@mui/material/Menu';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import MenuList from '@mui/material/MenuList';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ContentCut from '@mui/icons-material/ContentCut';
import ContentCopy from '@mui/icons-material/ContentCopy';
import ContentPaste from '@mui/icons-material/ContentPaste';
import Cloud from '@mui/icons-material/Cloud';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ListSubheader from '@mui/material/ListSubheader';
import Tooltip from '@mui/material/Tooltip';

import EditIcon from '@mui/icons-material/Edit';
import Chip from '@mui/material/Chip';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';



import UserContext from '../components/user-data';

//  var server = '/api';    
var server = 'https://ase-server.vercel.app/api/';

const BuyCard = (props) => {
  // URL Params
  // const queryParams = new URLSearchParams(window.location.search)
  // const session_id = queryParams.get("session_id")
    
      
  var firstName = '';
  var lastName = '';
  var appliedEvents = [];
  var eventName = '';
  var eventProduct = '';
  var matched = ''; 
  
  const navigate = useNavigate();

  const { user, loginWithRedirect, isAuthenticated, context, callback, auth0 } = useAuth0();
//  const [didBuy, access] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [fighters, setFighters] = React.useState([]);
  const [rows, setRows] = React.useState([]);

  //Base Time Variables
  var moment = require('moment-timezone');
  var today = Moment().format('ll');
  var now = Moment().format();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [values, setValues] = React.useState({
    product: props.product,
    event: props.item_name,
    song: null,
    songArtist: null,
    coach: null,
    commentary: null,
    matched: "Unmatched",
  });

  const [errors, setErrors] = React.useState({});
  const [loading, setLoading] = useState(true);

  const validate = () => {
    let temp= {};
    temp.song = values.song.length != 0 && values.song != '' ? "" : "Walk out song is required";
    temp.songArtist = values.songArtist.length != 0 && values.songArtist != '' ? "" : "Walk out song artist is required";
    temp.coach = values.coach.length != 0 && values.coach != '' ? "" : "Coach name is required";
    temp.commentary = values.commentary.length != 0 && values.commentary != '' ? "" : "Commentary is required";

    setErrors({
      ...temp
    });

    return Object.values(temp).every(x => x == "");
  };

  const handleChange = (event) => {
    if (setValues({
      ...values,
      [event.target.name]: event.target.value,
    })) {
      validate();
      console.log(errors);
    }

  };
    
  const [openAlert, setOpenAlert] = React.useState(false);

    useEffect(() => {
      getFighters();
    }, [getFighters]);
  
  const handleClickOpenAlert = () => {    
    
    //getFighters();
    if (renderFighters()) {
      //console.log(rows.sort());
      setOpenAlert(true);  
    }
  };


  const handleAlertSubmit = () => {
    //console.log(values.userEmail, values.fighter, values.product, values.event);
    purchase();
    window.open(streamLink, "_blank");
  };
  
  const handleAlertClose = () => {
    setOpenAlert(false);
    setRows([]);
  };
    
  const Transition = React.forwardRef(function Transition(
      props: TransitionProps & {
        children: React.ReactElement<any, any>;
      },
      ref: React.Ref<unknown>,
    ) {
      return <Slide direction="up" ref={ref} {...props} />;
  });


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  async function purchase() {
    const purchasing = await fetch (`${server}purchases/attempt`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: values.userEmail,
        product: values.product,
        event: values.event,
        fighter: values.fighter
      }),
    })
    .then((response) => response.json())
    .then((data) => {
      //document.getElementById('purchases').reset();
       //window.location.assign("/");
    //        getUser();
    //        console.log("Got user")

        setOpenAlert(false);
        setRows([]);
    })
    .catch((error) => {
      console. error ('Error:', error);
    });
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    if (validate()) {
      applyFighter();
//      console.log("Fighter Applied")
    }
    // console.log(values);


    async function applyFighter() {
      const updatingUser = await fetch (`${server}users/fighter-application`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: userEmail,
          product: values.product,
          event: values.event,
          song: values.song + " — " + values.songArtist,
          coach: values.coach,
          commentary: values.commentary,
          matched: values.matched,
        }),
      })
      .then((response) => response.json())
      .then((data) => {
        document.getElementById('fighter-application').reset();
         window.location.assign("/");
//        getUser();
//        console.log("Got user")
          
        setOpen(false);
        // setProfile(data);
      })
      .catch((error) => {
        console. error ('Error:', error);
      });
    }
  };
  
  async function getFighters() {
    const fetchingEvent = await fetch (`${server}event-fighters`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        event: values.product,
      }),
    })
    .then((response) => response.json())
    .then((data) => {

      setFighters(data);
      //renderFighters();
      
      // console.log(data)
    })
    .catch((error) => {
      console. error ('Error:', error);

    });
    
    
  }
  
  async function renderFighters() {
    //content

    const loadingEvents = await fighters.forEach((item, index)=>{
      
      firstName = item.fighter.firstName;
      lastName = item.fighter.lastName;
      appliedEvents = item.appliedEvents;

      appliedEvents.forEach((event, index)=>{
              
        if (event.product === values.product) {
            eventName = event.event;
            matched = event.matched;
        }  
        
        if (index === appliedEvents.length - 1) {
            setLoading(false);
        }
      });

      if (matched === null){
        matched = "Unmatched";
      }
        
      
      var fighter = firstName + " " + lastName;
      
      if (matched === "Matched" || matched === "Pending") {
        rows.push(fighter);
      }
          
      return rows.sort();

    });
  }


  // Product Variables
  // const product = 'price_1MOTeOIEfi3At4oIBHIRVDcU';
  // const livestream = '99Ea9fUQ';
  // const item_name = 'Arte Suave Elite XXI';
  // const item_url = '/events/images/asexxi-placeholder.png';
  // const item_price = '$20';
  //
  // var eventStartTime = '2023-01-14T16:00:00-08:00';
  // var eventEndTime = '2023-01-14T23:00:00-08:00';
  // var eventTimezone = 'America/Los_Angeles';
  // var eventTimezone = 'America/Phoenix'; // 'America/Los_Angeles'
    

  const product = props.product;
  const livestream = props.livestream;
  const item_name = props.item_name;
  const item_url = props.item_url;
  const item_price = props.item_price;
  const item_video = props.item_video;
  const item_recording = props.item_recording;
  const allowsFighters = props.allowsFighters;
  const stream_provider = props.stream_provider;
  const stream_key = props.stream_key;
  const ticketUrl = props.ticketUrl;
  const isActive = props.isActive;

  var eventStartTime = props.eventStartTime;
  var eventEndTime = props.eventEndTime;
  var eventTimezone = props.eventTimezone;

  var item_location = props.item_location;
  var item_address = props.item_address;

  var splitAddress = item_address.split(",");
  var item_city = splitAddress[1];
  var item_state = splitAddress[2].substring(0, 3);

  var location_url = `https://www.google.com/maps/search/?api=1&query=${splitAddress[0]}+${item_city}+${item_state}`;

  var event_title = item_name;
  

  const streamUrl = `/live?event=${item_recording}`;
  const fighterListUrl = `/fighters`;
  const profile = useContext(UserContext);


  var userEmail = null;
  var userSub = null;

  var splitEventName = null;
  var eventNameBase = null;
  var eventNameExtra = null;

  if (user) {
    userEmail = user.email;
    userSub = user.sub;
  }
  else {
    userEmail = null;
  }
  
  //console.log(values.userEmail);
  
  //Time Modifiers
  // var delay = false;
  // const delayDate = '7/21';
  var delay = props.delay;
  var delayDate = props.delayDate;
  var showStream = false;
  if (Moment(eventStartTime).format('ll') === today && Moment(eventEndTime).format() >= now) {
    showStream = true;
  }

  if (item_name.includes('(')) {
    splitEventName = item_name.split("(");
    eventNameBase = splitEventName[0];
    eventNameExtra = splitEventName[1];

    event_title = <div className="split-name"><div className="base">{eventNameBase}</div><div className="extra">({eventNameExtra}</div></div>;
  }
    
  var streamLink = `https://play.webvideocore.net/popplayer.php?it=${stream_key}&is_link=1&w=720&h=405&pause=1&skin=3&repeat=&brandNW=1&start_volume=34&bg_gradient1=%23000000&bg_gradient2=%23242128&fullscreen=1&fs_mode=2&skinAlpha=50&colorBase=%23242128&colorIcon=%23ffffff&colorHighlight=%23BDAA7A&direct=false&no_ctrl=&auto_hide=1&viewers_limit=1&cc_position=bottom&cc_positionOffset=70&cc_multiplier=0.03&cc_textColor=%23ffffff&cc_textOutlineColor=%23ffffff&cc_bkgColor=%23000000&cc_bkgAlpha=0.1&mainBg_Color=%23000000&aspect_ratio=16%3A9&play_button=1&play_button_style=pulsing&sleek_player=1&stretch=&auto_play=0&auto_play_type=unMute&floating_player=none`
    
  if (stream_provider === "Streaming Video Provider") {
      var calendarUrl = streamLink
  }
  else {
      var calendarUrl = `https://artesuaveelite.network${streamUrl}`
  }
    
  const setEvent = () => {
    window.location.assign("/");
  };

  //Calendar Generation
  const eventItem = {
    title: item_name,
    description: `To watch the PPV Live the day of the event, <a href=${calendarUrl}>click here</a> <br /><br /> To see the event in person, it will be held at <a target="_blank" href=${location_url}>${item_location} in ${item_city}, ${item_state}</a>`,
    location: item_address,
    start: eventStartTime,
    // duration: [4, "hour"],
    end: eventEndTime,
    allDay: false,
    url: calendarUrl,
  };

  let googleCalLink = google(eventItem); // https://calendar.google.com/calendar/render...
  let outlookCalLink = outlook(eventItem); // https://outlook.live.com/owa/...
  let officeCalLink = office365(eventItem); // https://outlook.office.com/owa/...
  let yahooCalLink = yahoo(eventItem); // https://calendar.yahoo.com/?v=60&title=...
  let appleCalLink = ics(eventItem); // standard ICS file based on https://icalendar.org
    

  //Check access
//  const purchased = localStorage.getItem(product) || false;

//  useEffect(() => {
//    if (purchased === true) {
//      access(true);
//      // console.log(purchased, didBuy);
//      return didBuy
//    }
//    else {
//      if (userSub != null && userEmail != null) {
//        checkAccess();
//      }
//    }
//  });

  // console.log(profile);

//  async function checkAccess() {
//    const accessData = await fetch (`${server}access`, {
//      method: 'POST',
//      headers: {
//        'Content-Type': 'application/json',
//      },
//      body: JSON.stringify({
//        user: userSub,
//        email: userEmail,
//        videoId: item_recording,
//      }),
//    })
//    .then((response) => response.json())
//    .then((data) => {
//      // var allPurchases = null
//      // if (data && data[0].app_metadata.some(e => e.purchases === product)) {
//      //   allPurchases =
//      // }
//      var purchaseStatus = "unpaid";
//      // if (data && data[0].app_metadata && data[0].app_metadata.some(e => e.purchases === product)) {
//      //   purchaseStatus = data[0].app_metadata.purchases.find(t=>t.price === product && t.status === "paid").status;
//      // }
//      if (data && data.purchases.find(t=>t.id === item_recording && t.status === "paid").status) {
//        purchaseStatus = "paid";
//      }
//
//      if ( purchaseStatus === "paid") {
//        localStorage.setItem(product, true);
//        access(true)
//        return didBuy
//      }
//    })
//    .catch((error) => {
//      console. error ('Error:', error);
//    });
//  }

//  async function getUser() {
//    const fetchingUser = await fetch (`${server}users`, {
//      method: 'POST',
//      headers: {
//        'Content-Type': 'application/json',
//      },
//      body: JSON.stringify({
//        email: userEmail,
//      }),
//    })
//    .then((response) => response.json())
//    .then((data) => {
//
//      if (data && data.email != userEmail) {
//        console.error("Empty array")
//      }
//      else {
//        setProfile(data);
//      }
//    })
//    .catch((error) => {
//      console. error ('Error:', error);
//    });
//  }
  var appleIcon =
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1755_1287)">
        <path d="M10.1265 5.74667C9.33651 5.74667 8.11401 4.84833 6.82651 4.88C5.12651 4.9025 3.56818 5.86583 2.69235 7.39167C0.928181 10.4542 2.23735 14.9775 3.95818 17.4667C4.80235 18.6783 5.79818 20.0417 7.11818 19.9992C8.38485 19.945 8.85985 19.1767 10.3973 19.1767C11.9232 19.1767 12.3557 19.9992 13.6973 19.9667C15.0615 19.945 15.9273 18.7333 16.7607 17.51C17.724 16.1033 18.124 14.7392 18.1457 14.6642C18.1132 14.6533 15.494 13.6467 15.4623 10.6167C15.4407 8.08333 17.529 6.87167 17.6265 6.8175C16.4357 5.07583 14.6073 4.88083 13.9682 4.8375C12.3015 4.7075 10.9057 5.74583 10.1265 5.74583V5.74667ZM12.9415 3.19167C13.644 2.34833 14.1082 1.16917 13.979 0C12.9732 0.0433333 11.7607 0.670833 11.0357 1.515C10.3857 2.26167 9.82401 3.46333 9.97485 4.61C11.0898 4.69667 12.2373 4.03667 12.9407 3.1925" fill="black" fill-opacity="0.54"/>
        </g>
        <defs>
        <clipPath id="clip0_1755_1287">
        <rect width="20" height="20" fill="white"/>
        </clipPath>
        </defs>
      </svg>;
  
  var googleIcon =
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1755_1289)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.0459 5.45834C13.0043 4.46667 11.6876 3.95833 10.2126 3.95833C7.60426 3.95833 5.39593 5.71667 4.60427 8.09167L4.60407 8.09152V8.09172C4.40407 8.69172 4.2874 9.33339 4.2874 10.0001C4.2874 10.6667 4.39573 11.3083 4.60405 11.9083H4.60427C5.39593 14.2833 7.60427 16.0417 10.2126 16.0417C11.5625 16.0417 12.7041 15.675 13.6041 15.0751V15.075C14.6541 14.3667 15.3624 13.325 15.6041 12.0917H10.2124V8.33337H19.6291C19.7291 8.94171 19.7874 9.56671 19.7874 10.225C19.7874 13.2666 18.7041 15.8333 16.8209 17.5749L16.8209 17.575C15.1709 19.1 12.9126 20 10.2126 20C6.30427 20 2.92926 17.75 1.2876 14.4832L1.2874 14.4834C0.604068 13.1334 0.212402 11.6167 0.212402 10.0001C0.212402 8.38339 0.604068 6.86672 1.2874 5.51672H1.28767L1.2876 5.51667C2.92926 2.25 6.30427 0 10.2126 0C12.9126 0 15.1709 0.991667 16.8959 2.60833L14.0459 5.45834Z" fill="black" fill-opacity="0.54"/>
        </g>
        <defs>
        <clipPath id="clip0_1755_1289">
        <rect width="20" height="20" fill="white"/>
        </clipPath>
        </defs>
      </svg>;
    
  var outlookIcon =
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M19.2666 4.50012H12V6.97913H19.0001V14.7502H12V15.5003H19.2666C19.6716 15.5003 20.0001 15.1718 20.0001 14.7668V5.23363C20.0001 4.82863 19.6716 4.50012 19.2666 4.50012Z" fill="black" fill-opacity="0.54"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 17.7502L11.5002 20.0003V0L0 2.25003V17.7502ZM2.74951 10.0002C2.74951 7.92914 4.03653 6.25012 5.62455 6.25012C7.21257 6.25012 8.49959 7.92914 8.49959 10.0002C8.49959 12.0712 7.21257 13.7502 5.62455 13.7502C4.03653 13.7502 2.74951 12.0712 2.74951 10.0002ZM3.99953 10.0002C3.99953 11.2427 4.67104 12.2502 5.49955 12.2502C6.32806 12.2502 6.99957 11.2427 6.99957 10.0002C6.99957 8.75766 6.32806 7.75014 5.49955 7.75014C4.67104 7.75014 3.99953 8.75766 3.99953 10.0002Z" fill="black" fill-opacity="0.54"/>
        <path d="M13.3739 12.8687H12.0239V14.3187H13.3739V12.8687Z" fill="black" fill-opacity="0.54"/>
        <path d="M15.0746 12.8687H13.7246V14.3187H15.0746V12.8687Z" fill="black" fill-opacity="0.54"/>
        <path d="M16.7753 12.8687H15.4253V14.3187H16.7753V12.8687Z" fill="black" fill-opacity="0.54"/>
        <path d="M13.3739 11.0797H12.0239V12.5297H13.3739V11.0797Z" fill="black" fill-opacity="0.54"/>
        <path d="M15.0746 11.0797H13.7246V12.5297H15.0746V11.0797Z" fill="black" fill-opacity="0.54"/>
        <path d="M16.7753 11.0797H15.4253V12.5297H16.7753V11.0797Z" fill="black" fill-opacity="0.54"/>
        <path d="M18.4745 11.0797H17.1245V12.5297H18.4745V11.0797Z" fill="black" fill-opacity="0.54"/>
        <path d="M13.3739 9.35315H12.0239V10.8032H13.3739V9.35315Z" fill="black" fill-opacity="0.54"/>
        <path d="M15.0746 9.35315H13.7246V10.8032H15.0746V9.35315Z" fill="black" fill-opacity="0.54"/>
        <path d="M16.7753 9.35315H15.4253V10.8032H16.7753V9.35315Z" fill="black" fill-opacity="0.54"/>
        <path d="M18.4745 9.35315H17.1245V10.8032H18.4745V9.35315Z" fill="black" fill-opacity="0.54"/>
        <path d="M15.0746 7.55603H13.7246V9.00605H15.0746V7.55603Z" fill="black" fill-opacity="0.54"/>
        <path d="M16.7753 7.55603H15.4253V9.00605H16.7753V7.55603Z" fill="black" fill-opacity="0.54"/>
        <path d="M18.4745 7.55603H17.1245V9.00605H18.4745V7.55603Z" fill="black" fill-opacity="0.54"/>
      </svg>;
  
    
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openCal = Boolean(anchorEl);
    const handleClickCal = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseCal = (event, calendar) => {
        if (calendar === "apple") {
            window.open(appleCalLink, "_blank", "noreferrer");
//            console.log("Apple Event");
        }
        else if (calendar === "google") {
            window.open(googleCalLink, "_blank", "noreferrer");
//            console.log("Google Event");
        }
        else if (calendar === "outlook") {
            window.open(outlookCalLink, "_blank", "noreferrer");
//            console.log("Outlook Event");
        }
        
        setAnchorEl(null);
    };
    
    
  var calendarMenu =
  <Paper sx={{ width: 320, maxWidth: '100%' }}>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openCal}
        onClose={handleCloseCal}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuList subheader={<ListSubheader>Add to Calendar</ListSubheader>}>
            <MenuItem onClick={event => handleCloseCal(event, "apple")} >
              <ListItemIcon>
                {appleIcon}
              </ListItemIcon>
              <ListItemText>Apple Calendar</ListItemText>
            </MenuItem>
            <MenuItem onClick={event => handleCloseCal(event, "google")} >
              <ListItemIcon>
                {googleIcon}
              </ListItemIcon>
              <ListItemText>Google Calendar</ListItemText>
            </MenuItem>
            <MenuItem onClick={event => handleCloseCal(event, "outlook")} >
              <ListItemIcon>
                {outlookIcon}
              </ListItemIcon>
              <ListItemText>Outlook</ListItemText>
            </MenuItem>
        </MenuList>
      </Menu>
    </Paper>;
    

 if (ticketUrl) {
     var ticketButton =
         <a className="cta" href={ticketUrl} target="_blank"><button className="alt">Access Venue Pre-Sales Tickets</button></a>
 }
 else {
     var ticketButton = ''
 }


  var content =
  <div className="info">
    <div className="event_name">{event_title}</div>
    <div className="meta-bar">
      <div className="meta">
          <Tooltip title="Add to calendar" placement="right"
            slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, -10],
                      },
                    },
                  ],
                },
              }}
            >
            <div className="date" aria-controls={openCal ? 'calendar-menu' : undefined} aria-haspopup="true" aria-expanded={openCal ? 'true' : undefined} onClick={handleClickCal} >
              <CalendarMonthIcon fontSize="small" />
              <div>{Moment(eventStartTime).format('ddd, MMM Do')}</div>
              <div>{Moment(eventStartTime).tz(eventTimezone).format('h:mm a z')}</div>
              <ArrowDropDownIcon />
            </div>
          </Tooltip>
        {calendarMenu}
        <Tooltip title="Open in Google Maps" placement="right"
            slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, -10],
                      },
                    },
                  ],
                },
              }}
            >
            <div className="event_location"><a target="_blank" href={location_url}><PlaceIcon fontSize="small" />{item_city}, {item_state}</a></div>
        </Tooltip>
      </div>
      <h6 className="price">{item_price}</h6>
    </div>
  </div>;

  // console.log(Moment(eventStartTime).subtract(7, 'days'));
  // <BuyCard product={product} livestream={livestream} item_name={item_name} item_url={item_url} item_price={item_price} eventStartTime={eventStartTime} eventEndTime={eventEndTime} eventTimezone={eventTimezone} delay={delay} delayDate={delayDate} allowsFighters={allowsFighters} key={product} />
    
  const inputRef = useRef(null);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (open && inputRef.current) {
        inputRef.current.focus();
      }
    }, 0);

    return () => clearTimeout(timeout);
  }, [open]);

    
  var fighterApplication =
  <Box
    component="form"
    sx={{
      '& .MuiTextField-root': { m: 1, width: '25ch' },
    }}
    autoComplete="off"
    id="fighter-application"
  >
    <Dialog
      fullScreen={fullScreen}
      open={open}
      // onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        <Box
          sx={{
            display: 'flex',
          }}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box
            className="subtitle"
          >
            Fighter Application
          </Box>
          <CloseRoundedIcon onClick={handleClose} className="close-button" />
        </Box>
        <Box
        >
          {values.event}
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>

        </DialogContentText>
        <Alert className="alert" severity="warning" action={<Button color="inherit" size="small"><Link to="/profile">view profile</Link></Button>}>Make sure your profile is up to date before applying.</Alert>
        <TextField
          required
          disabled
          sx={{
              display: 'none'
          }}
          margin="normal"
          id="product"
          label="Product"
          type="hidden"
          fullWidth
          variant="outlined"
          size="normal"
          name="product"
          defaultValue={values.product ?? ''}
          onChange={handleChange}
          placeholder="price_1LVMH2IEfi3At4oI9LH3FfNF"
          error={errors.product || values.product === '' ? true : false}
          helperText={errors.product}
        />
        <TextField
          required
          disabled
          sx={{
              display: 'none'
          }}
          margin="normal"
          id="event"
          label="Event"
          type="hidden"
          fullWidth
          variant="outlined"
          size="normal"
          name="event"
          defaultValue={values.event ?? ''}
          onChange={handleChange}
          placeholder="Arte Suave Elite XYZ"
          error={errors.event || values.event === '' ? true : false}
          helperText={errors.event}
        />
        <TextField
          // autoFocus
          inputRef={inputRef}
          required
          margin="normal"
          id="coach"
          label="Coach"
          type="text"
          fullWidth
          variant="outlined"
          size="normal"
          name="coach"
          onChange={handleChange}
          onBlur={handleChange}
          placeholder="Chad Bingham"
          error={errors.coach || values.coach === '' ? true : false}
          helperText={errors.coach}
        />
        <TextField
          required
          margin="normal"
          id="song"
          label="Walkout Song"
          type="text"
          fullWidth
          variant="outlined"
          size="normal"
          name="song"
          onChange={handleChange}
          onBlur={handleChange}
          placeholder="Eye of the Tiger"
          error={errors.song || values.song === '' ? true : false}
          helperText={errors.song}
        />
        <TextField
          required
          margin="normal"
          id="songArtist"
          label="Walkout Song Artist"
          type="text"
          fullWidth
          variant="outlined"
          size="normal"
          name="songArtist"
          onChange={handleChange}
          onBlur={handleChange}
          placeholder="Survivor"
          error={errors.songArtist || values.songArtist === '' ? true : false}
          helperText={errors.songArtist}
        />
        <TextField
          required
          multiline
          minRows={2}
          margin="normal"
          id="commentary"
          label="What would you like our commentators to say about you?"
          type="text"
          fullWidth
          variant="outlined"
          size="normal"
          name="commentary"
          onChange={handleChange}
          onBlur={handleChange}
          placeholder="List accomplishments or details"
          error={errors.commentary || values.commentary === '' ? true : false}
          helperText={errors.commentary}
        />
              
        <FormControlLabel required disabled control={<Checkbox defaultChecked />} label={<span><span>By applying you agree to the </span><Link className="inline-link" to="./ASE - Release & Waiver of Liability.pdf" target="_blank">terms and liability waiver</Link></span>} />

      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          flexDirection: 'row',
          m: 'auto',
          width: 'fit-content',
        }}
      >
        <button onClick={handleSubmit} className="cta" type="submit" value="Submit" autoFocus>
          Apply
        </button>
      </DialogActions>
    </Dialog>
  </Box>;


   if (isAuthenticated && profile?.role === "admin" ){
//      const dataToPass = { product: "price_1Oc27HIEfi3At4oIllTHNKhJ", item_name: "Kratos Wrestling Series #3 (Spring Forward Event)" };
//       console.log(product + " " + item_name)
      var fighterList = 
      <div className="fighter-list">
          <Link to={fighterListUrl} state={{ product: product, item_name: item_name }} >
            <ListIcon 
                fontSize="small"
            /> 
            <span>View Fighter List</span>
          </Link>
      </div>
       
     var editEvent = 
       <Tooltip title="Edit event" placement="top"
                slotProps={{
                    popper: {
                    modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, -10],
                      },
                    },
                  ],
                },
              }}
            >
        <Link to="/" className="edit-event-button"  state={{ product: product, item_name: item_name, livestream: livestream, image: item_url, price: item_price, stream_provider: stream_provider, recording: item_recording, ticketUrl: ticketUrl, eventTimezone: eventTimezone, eventStartTime: eventStartTime, eventEndTime: eventEndTime, venueName: item_location, venueAddress: item_address, allowsFighters: allowsFighters, isActive: isActive}} >
            <IconButton aria-label="Edit event" color="primary">
                <EditIcon />
            </IconButton>
        </Link>
       </Tooltip>
  } 
  else {
     var figherList = ""
     var editEvent = ""
  }
  
  var alert = 
       <Dialog
        open={openAlert}
        onClose={handleAlertClose}
        disableBackdropClick
        disableEscapeKeyDown
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{
          component: 'form',
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            //const formData = new FormData(event.currentTarget);
            //const formJson = Object.fromEntries((formData as any).entries());
            //const email = values.userEmail;
            //const figher = values.fighter;
            //console.log(formData); 
            handleAlertSubmit();
          },
        }}
      >
        <DialogTitle>{"Access " + item_name + " PPV"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description"> 
          </DialogContentText>
          <Alert className="alert" severity="warning">Make sure you enable pop up windows before accessing the PPV stream.</Alert>
          <TextField
            autoFocus
            required
            margin="normal"
            size="normal"
            id="email"
            name="userEmail"
            label="Email Address"
            type="email"
            defaultValue={userEmail}
            onChange={handleChange}
            onBlur={handleChange}
            fullWidth
            helperText={errors.userEmail ? errors.userEmail : "Use the same email to make the purchase."}
          />
          <TextField
            required
            disabled={loading ?? true}
            placeholder="Select..."
            margin="normal"
            size="normal"
            id="fighter"
            select
            fullWidth
            label={loading ? "Loading Fighters..." : "Which fighter are you supporting?"}
            name="fighter"
            value={values.fighter ?? ''}
            onChange={handleChange}
            error={errors.fighter || values.fighter === '' ? true : false}
            helperText={errors.fighter ? errors.fighter : "Fighters will appear as they are pending or confirmed for a match."}
          >
            <MenuItem value={''} disabled>Select...</MenuItem>
            <MenuItem value={'N/A'}>Not Applicaple / Not Listed</MenuItem>
            {rows && rows.map(function(data) {
              return (
                <MenuItem value={data}>{data}</MenuItem>
              )
            })}
          </TextField>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleAlertClose}>Cancel</Button>
          <Button disabled={!values.fighter || !values.userEmail ? true : false } type="submit">Continue</Button>
        </DialogActions>
      </Dialog>;

  var apply =
    <div className="fighter-application">
      <span className="disclaimer">This event is not accepting fighter applications at this time</span>
    </div>;

  if (allowsFighters === true) {

    if (profile === undefined && userEmail !== null){
//      getUser();
//      console.log("Got user")
    }
    else if (profile && profile.appliedEvents && profile.appliedEvents.some(e => e.product === product)) {
      apply =
        <div className="fighter-application">
          <span className="disclaimer">You have applied to fight in this event</span>
        </div>;
    }
    else if (profile && profile.fighter && profile.fighter.isActive === true && Moment(eventStartTime).diff(today, 'days')  >= 0) {
      apply =
        <div className="fighter-application">
          <span className="title">Are you a fighter?</span>
          <Link className="apply-link" to="" onClick={handleClickOpen}>Apply now</Link>
        </div>;

      // apply = `<span class="title">Are you a fighter? </span><a onClick=${handleClickOpen}>Apply now</a>`;
    }
    else if (isAuthenticated === false && Moment(eventStartTime).diff(today, 'days')  >= 0) {
      apply =
        <div className="fighter-application">
          <span className="title">Are you a fighter?</span>
          <Link className="apply-link" to="" onClick={() => loginWithRedirect()}>Login to Apply</Link>
        </div>;
    }
    else if (Moment(eventStartTime).diff(today, 'days')  >= 0) {
      apply =
        <div className="fighter-application">
          <span className="title">Are you a fighter?</span>
          <Link className="apply-link" to="/profile">Register then Apply</Link>
        </div>;
    }
  }

//  if (delay) {
//    return (
//        <div key={product} className="buy-card">
//          <img src={item_url}/>
//          <div className="details">
//            {content}
//            {editEvent}
//            <button disabled>Available to purchase on {delayDate}</button>
//            {apply}
//            {fighterApplication}
//            {fighterList}
//          </div>
//        </div>
//      );
//  }

  if (isActive === false && isAuthenticated && profile?.role === "admin") {
      
     return (
        <div key={product} className="buy-card inactive">
          <img src={item_url}/>
          <div className="details">
            {content}
            {editEvent}
            <Chip className="chip" label="Inactive" color="error" icon={<DoDisturbOnIcon />} />
            <div className="purchase-options">
                <button className="cta" onClick={handleClickOpenAlert}>Access PPV {item_price}</button>
                {alert}
                {ticketButton}
            </div>
              {apply}
              {fighterApplication}
              {fighterList}
          </div>

        </div>
      );
      
  }
  else if (isActive === true) {
      return (
        <div key={product} className="buy-card">
          <img src={item_url}/>
          <div className="details">
            {content}
            {editEvent}
            <div className="purchase-options">
                <button className="cta" onClick={handleClickOpenAlert}>Access PPV {item_price}</button>
                {alert}
                {ticketButton}
            </div>
              {apply}
              {fighterApplication}
              {fighterList}
          </div>

        </div>
      );
  }
//  
//  if (eventCount < 1) {
//      return (
//        <div className="buy-card">
//          <div className="details">   
//            <div className="event_name">No upcoming events</div>
//          </div>
//
//        </div>
//      );
//  }

//  else if (stream_provider === 'Streaming Video Provider') {
//    return (
//        <div key={product} className="buy-card">
//          <img src={item_url}/>
//          <div className="details">
//            {content}
//            {editEvent}
//            <div className="purchase-options">
//                <a className="cta" href={streamLink} target="_blank"><button>Access PPV {item_price}</button></a>
//                {ticketButton}
//            </div>
//              {apply}
//              {fighterApplication}
//              {fighterList}
//          </div>
//          
//        </div>
//      );
//  }

//  else if (!isAuthenticated) {
//    return (
//        <div key={product} className="buy-card">
//          <img src={item_url}/>
//          <div className="details">
//            {content}
//            {editEvent}
//            <button onClick={() => loginWithRedirect()}>Login to access PPV</button>
//            {apply}
//            {fighterApplication}
//            {fighterList}
//          </div>
//        </div>
//      );
//  }
//  else if (isAuthenticated && showStream === false && Moment(eventEndTime).format() < now) {
//    content = <div className="content-container">Thanks for purchasing the {item_name} PPV. The event is no longer streaming live, but will be added to the library for additional access.</div>;
//
//    return (
//      isAuthenticated && (
//        <div key={product} className="buy-card">
//          <img src={item_url}/>
//          <div className="details">
//            {content}
//            <Link className="cta" to="/library"><button>Go to Library</button></Link>
//          </div>
//        </div>
//      )
//    );
//  }
//  else if (isAuthenticated && showStream === false) {
//    content = <div className="content-container">You have purchased the {item_name} PPV taking place in {item_city}, {item_state}. Come back {Moment(eventStartTime).format('ddd, MMM Do')} at {Moment(eventStartTime).tz(eventTimezone).format('h:mm a z')} to watch the event.</div>;
//
//    return (
//      isAuthenticated && (
//        <div key={product} className="buy-card">
//          <img src={item_url}/>
//          <div className="details">
//            {content}
//            {editEvent}
//            {apply}
//            {fighterApplication}
//            {fighterList}
//          </div>
//        </div>
//      )
//    );
//  }
//  else if (isAuthenticated) {
//    content = <div className="content-container">Thanks for purchasing the {item_name} PPV taking place in {item_city}, {item_state}. The event will start streaming today at {Moment(eventStartTime).tz(eventTimezone).format('h:mm a z')}</div>;
//
//    return (
//      isAuthenticated && (
//        <div key={product} className="buy-card">
//          <img src={item_url}/>
//          <div className="details">
//            {content}
//            <Link className="cta" to={streamUrl}><button>Watch the Event Live</button></Link>
//          </div>
//        </div>
//      )
//    );
//  }
//  else {
//    return (
//      isAuthenticated && (
//        <div key={product} className="buy-card">
//          <img src={item_url}/>
//          <div className="details">
//            {content}
//            {editEvent}
//            <form className="cta" action="https://ase-server.vercel.app/api/create-checkout-session" method="POST">
//              <input type="hidden" id="customer_email" name="email" value={user.email}></input>
//              <input type="hidden" id="product_price" name="price" value={product}></input>
//              <input type="hidden" id="product_id" name="videoId" value={item_recording}></input>
//              <button className="cta" type="submit" id="checkout-button">Buy PPV</button>
//            </form>
//            {apply}
//            {fighterApplication}
//            {fighterList}
//          </div>
//        </div>
//      )
//    );
//  }


};

export default BuyCard;

// <div className="content-container" dangerouslySetInnerHTML={{ __html: content }} />

// return (
//   <div className="buy-card">
//     <img src={item_url}/>
//     <div class="content-container" dangerouslySetInnerHTML={{ __html: content }} />
//     </div>
// );

// Windows IconButton
// <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
//     <path d="M12 12V23.408H23.408V12H12ZM24.594 12V23.408H36V12H24.594ZM12 24.594V36H23.408V24.594H12ZM24.594 24.594V36H36V24.594H24.594Z" fill="black"/>
// </svg>


//<IconButton
//  aria-label="close"
//  onClick={handleAlertClose}
//  sx={(theme) => ({
//    position: 'absolute',
//    right: 8,
//    top: 8,
//    color: theme.palette.grey[500],
//  })}
//>
//  <CloseRoundedIcon onClick={handleAlertClose} className="close-button" />
//</IconButton>